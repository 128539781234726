body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


thead[class*="ant-table-thead"] th {
  background:   #dbdbdb;
  font-size: 16px;
  font-weight: bold;
  color: #140035;
}

.ant-table-tbody > tr > td {
  max-width: 200px;
  overflow: hidden;
  color: #140035;
  border: 1px solid rgba(179, 179, 179, 0.5);
}