
.custom-selected .ant-menu-item-selected {
background: linear-gradient(135deg, #0C850F, #08699A) !important;
  color: white !important;
}

.custom-selected .ant-menu-item-selected .anticon {
  color: white !important;
}

/* Custom styles for the hover state */
.custom-selected .ant-menu-item:hover {
 background: linear-gradient(135deg, #0C850F, #08699A) !important;
  color: white !important;
}

.custom-selected .ant-menu-item:hover .anticon {
  color: white !important;
}

/* Custom styles for the active state */
.custom-selected .ant-menu-item:active {
background: linear-gradient(135deg, #0C850F, #08699A) !important;
  color: white !important;
}

.custom-selected .ant-menu-item:active .anticon {
  color: white !important;
}

.ant-layout-sider-collapsed-trigger,
.ant-layout-sider-trigger {
background: linear-gradient(135deg, #0C850F, #08699A) !important;
  color: white !important;
  border: none !important;
  color: white !important;
}


.ant-layout-sider-collapsed-trigger:hover,
.ant-layout-sider-trigger:hover {
 background: linear-gradient(135deg, #0C850F, #08699A) !important;
}

.ant-layout-sider-collapsed-trigger:active,
.ant-layout-sider-trigger:active {
 background: linear-gradient(135deg, #0C850F,  #0C850F) !important;
}


.custom-selected .ant-menu-item {
  font-size: 16px; 
}

.custom-selected .ant-menu-item .anticon {
  font-size: 18px;
}

