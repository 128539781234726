#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout {
  background: #fff;
}

.site-layout-background {
  background: #140035;
}

.ant-layout-sider-trigger {
  background-color: rgba(141, 141, 141, 0.1);
}
